
import { Options, Vue } from 'vue-class-component'
import PlanDetail from '@/components/PlanDetail.vue'
import service from '@/utils/request'
import dayjs from 'dayjs'
import { Plan, PlanStatus } from '@/models/plan'
import NaviBar from '@/components/NaviBar.vue'
import { AuthStore } from '@/store/modules/auth'

@Options({
  components: {
    NaviBar
  }
})
export default class PlanChange extends Vue {
  plans: Plan[] = []
  authStore = AuthStore
  status = PlanStatus

  mounted () {
    this.loadData()
  }

  loadData () {
    service.get('/mindup/mini/plans/current-teacher-plan', {}).then(res => {
      this.plans = res.data
    })
  }

  getDate (date: string) {
    return dayjs(date).format('M月D日')
  }

  tapPlan (index: number) {
    this.$router.push({
      name: 'StudentManagement',
      params: {
        planId: this.plans[index].id,
        applyNum: this.plans[index].applyNum + ''
      }
    })
  }
}
